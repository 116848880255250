import { graphql, PageRendererProps, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { createGlobalStyle } from "styled-components"
import { Layout } from "../components/layout"
import { FadeLink } from "../components/link"
import { SEO } from "../components/seo"
import { Toolbar } from "../components/toolbar"
import constants from "../utils/constants"

const StyledLink = styled(FadeLink)`
  box-shadow: none;
  color: ${constants.colors.links};
`

const Link = styled.h3`
`

type Props = PageRendererProps

const BlogIndex = (props: Props) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allMdx(sort: { fields: [frontmatter___sequencing], order: ASC }) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              backgroundColor
              date(formatString: "MMMM DD, YYYY")
              sequencing
              tags
              title
              description
            }
          }
        }
      }
    }
  `)

  const frontmatter = data.frontmatter
  const siteTitle = data.site.siteMetadata.title
  const frontmatterBackgroundColor = frontmatter?.backgroundColor;
  const backgroundColor = (frontmatterBackgroundColor && frontmatterBackgroundColor.length)
    ?? constants.colors.background
  const GlobalStyle = createGlobalStyle`
    body {
      background-color: ${backgroundColor};
      color: white;
    }
  `
  return (
    <Layout location={props.location} title={siteTitle}>
      <GlobalStyle />
      <SEO
        title="All posts"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      <Toolbar />
      <p>
      Download <a href="https://apps.apple.com/us/app/pathify/id1476942075">Pathify for iOS</a> from the App Store.
      </p>
      <p>
      Learn more about the <a href="https://www.dubman.com/narratives/pathify">story of Pathify</a> here.
      </p>
    </Layout>
  )
}

export default BlogIndex
